.words{
  font-family: 'DM Sans';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 23px; 
text-transform: capitalize;
letter-spacing: 1px;
}

.puzzleText{
  font-family: 'DM Sans';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 26px; 
align-items: center;
text-align: center;
text-transform: capitalize; 
}

@keyframes wrongTextSelection { 
  0% {
      background-color: #cae5ff;
    }
    50% {
      background-color: #ffacac;
    }
    100% {
      background-color: #FAFCFE;
    }
}


@keyframes correctTextSelection { 
  0% {
      background-color: #cae5ff;
    }
    50% {
      background-color: #acffb3;
    }
    100% {
      background-color: #acffb3;
    }
}


@keyframes strikeLine{
  0%   { width : 0; }
  100% { width: 100%; }
}
.strikeLine {
  position: relative;
}
.strikeLine::after {
  animation-name: strikeLine;
  animation-duration: 1s;
  position: absolute;
  height: 1px;
  animation-fill-mode: forwards; 
  top: 50%;
  left: 0;
  background: rgb(65, 64, 64);
  content: ' ';
  animation-iteration-count: 1;
  width: 100%;
  animation-timing-function: linear;
}


.question {
  font-family: "Circular Std";
  font-style: normal;
  text-align: center;
  color: #0a2551;
}

.charInputStyle{
background: #FFFFFF;
border: 1px solid #8A929E; 
text-align: center;
padding-bottom: 0.1em;
font-weight: 400;
caret-color: transparent;
}



.connectionDivParent{  
height: fit-content;
} 
.connectionChild{  
/* padding: 3px 2px 4px 2px; */

padding-bottom: 10px;
padding-top: 12px;
padding-left: 4px;
padding-right: 4px;
background: #f0f8fffc;
border-radius: 12px;
height: auto;
display: flex;
max-height: 290px;  
min-height: 167px;
} 

.puzzleTips{    
background: white;
padding: 10px 19px 10px 19px;
border-radius: 16px;
width: fit-content;
border: 1px solid #0a257278;
}

.findFlowImage{  
position: absolute;
top: 0px;
left: 0px;
width: 100%;
height: 100%;
border-radius: 26px;
display: flex; 
}

.fitImage{                                 
background-repeat: no-repeat !important;
background-position: center !important
}

.orignalImage{ 
margin: auto;
max-width: 100%;
max-height: 92%;
}