.engageLoading {
  display: inline-block;
  width: 3%;
  max-width: 32px;
  min-width: 24px;
  aspect-ratio: 1/1;
  border: 3px solid rgb(215 215 215);
  border-radius: 55%;
  border-top-color: #2563eb;
  border-right-color: #2563eb;
  animation: engageSpin 1s ease-in-out infinite;
  -webkit-animation: engageSpin 1s ease-in-out infinite;
  margin-bottom: 2%;
}
.engageLoadingDiv {
  width: 100%;
  height: 100%;
  background: #eaeaea;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 20;
  position: fixed;
  top: 0px;
  left: 0px;
}


@keyframes engageSpin {
    to { -webkit-transform: rotate(360deg); }
  }
  @-webkit-keyframes engageSpin {
    to { -webkit-transform: rotate(360deg); }
  }
  
