.heading {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #0a2551;
}

.question {
  font-family: "Circular Std";
  font-style: normal;
  /* font-weight: 700;
  font-size: 24px;
  line-height: 43px; */
  text-align: center;
  color: #0a2551;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.noselect::-moz-selection {   background: #FAFCFE; }
.noselect::selection {  background: #FAFCFE; }

.fillHight {
  height: 100%;
  height: -moz-available;          /* WebKit-based browsers will ignore this. */
  height: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
  height: fill-available;
}


@media only screen and (max-width: 600px) {
  .heading { 
    font-weight: 700;
    font-size: 20px;
    line-height: 30px; 
  } 
  
.question {  
  /* font-weight: 600;
  font-size: 20px;
  line-height: 30px;   */
}
}


.charInputStyle{
  background: #FFFFFF;
  border: 1px solid #8A929E; 
  text-align: center;
  padding-bottom: 0.1em;
  font-weight: 400;
  caret-color: transparent;
}

/* REMOVED FORM REARRANGE BECAUSE WE USING SCALE
 .selectedMatchPair{ 
  background-color: rgb(233 244 255);
  border: 3px solid #06d455ad;*/
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
/*}  */

#confetti_canvas { 
  position: absolute;
}

.confetti {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  opacity: 0.7;
} 



.scratchcontainer {
  border: 3px solid yellow;
  position: relative;
  width: 300px;
  height: 300px;
  margin: 0 auto;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none; 
  -o-user-select: none;
  user-select: none;
}

.scratchcanvas {
  position: absolute;
  top: 0;
}


.square {
  width: 100%;
  position: relative;
  margin: auto;
  height: 50%;
}

.square:after {
  content: "";
  display: block;
  margin: auto;
  padding-bottom: 100%;
}

.fitImageBg{
  background-repeat: no-repeat !important;
  background-position: center !important; 
  /* background-size: contain !important; */
}

.orignalImage{    
  margin: auto;
  max-width: 100%;
  max-height: 92%; 
  height: fit-content;
}


.gameSimpleScroll {
  /* overflow: scroll; */
  background-color: transparent;
}
.gameSimpleScroll::-webkit-scrollbar {
   width: 7px;
   height: 7px;

}

.gameSimpleScroll::-webkit-scrollbar-track {
   border-radius: 10x;
   background-color: #ededed;
}

.gameSimpleScroll::-webkit-scrollbar-thumb {
   border-radius: 10x;
   background: #bbbbbb;
}

.FindFlowgamecard {
  position: relative; 
  cursor: pointer;
  box-shadow: 0px 0px 2px 0px gray;
  transition: transform 0.6s;
  transform-style: preserve-3d;  
}

.SimpleScratchCard canvas{
  touch-action: none;
  cursor: pointer;
  border-radius: 9px;
}

.scratchCardStyle{
  position: absolute;
  z-index: 11;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background: rgb(163 163 163 / 55%);
}
.scratchCardParent{
  touch-action: none;
  width: 75%;
  min-width: 290px;
  max-width: 579px;
  height: 68%;
  min-height: 250px;
  max-height: 331px;
}
.scratchCardInner{
  width: 100%;
  height: 100%;
  touch-action: none;
  background: white;
  border-radius: 9px;
  aspect-ratio: 1/1;
  padding: 2rem;
}

.engage-close-btn {
  top: 1%;
  right: 1%;
  border: 1px solid lightgray;
  position: absolute;
  cursor: pointer;
  z-index: 16777269;
  background: white;
  border-radius: 50%;
}
.engage-close-btn:hover {
  background-color: #EAF1F3;
}