
.welcomepageBlock,.thankpageBlock {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  }
 
  .gamecard {
    position: relative; 
    cursor: pointer;
    box-shadow: 0px 0px 2px 0px gray;
    transition: transform 0.6s;
    transform-style: preserve-3d;  
}

.flexChild {
  flex: 1 0 21%; /* explanation below */
  margin: 5px;
  height: 100px; 
  max-width: 50%;
}