.App {
    text-align: center;
  }
  html, body, #root, #root>div {
    height: 100%
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }

  html {
    --scrollbarBG: #e0e0e0;
    --thumbBG: #b2c0c7;
  }
  body::-webkit-scrollbar {
    width: 11px;
  }
  body { 
    scrollbar-color: var(--thumbBG) var(--scrollbarBG);
  }
  body::-webkit-scrollbar-track {
    background: var(--scrollbarBG);
  }
  body::-webkit-scrollbar-thumb {
    background-color: var(--thumbBG) ;
    border-radius: 6px;
    border: 3px solid var(--scrollbarBG);
  } 
 

  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  body.drag
{
  cursor: none;
  user-select: none;
}
  
  .gamecontainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    
    min-height: 100% !important;
    max-height: fit-content !important;

    position: relative;
    /* height: fit-content; */
    /* margin: 1% auto; REMOVED WHITE SPACE ON TOP*/ 
    background: #FAFCFE;
    padding: 1rem;
    /* border-radius: 26px; */
    /* border: 1px solid gray; */
  }
  @media (min-width: 750px) {
    .gamecontainer {  
      padding: 2rem;
    }
}

  .gamepanel {
    width: 100%;
    display: grid;
    grid-gap:2rem;
    /* grid-template-rows: auto auto auto;
    grid-template-columns: auto auto auto auto; */
  }
  .activecard{
    transform: rotateY(180deg);
  }.deactivecard{
    transform: rotateY(0deg);
  } 
  .gamecardFBmodile{
    border-radius: 10px !important;
    padding: 1rem;
  
  }
  
  .gamecardfront,.gamecardback{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    /* border-radius: 10px !important; */
    display: flex;
    backface-visibility: hidden;
  
  }.gamecardinner{
    display: flex;
    margin: auto; 
   height: 80%;
  }
  .gamecardback{     
    background-color: white;
    transform: rotateY(180deg);
  }
  .gameWelcomePage{
    position: absolute;
    z-index: 10;
    background-color: rgba(250, 250, 250, 0.5);
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    left: 0;
  }
  .but{
    padding: 0.75rem 1.5rem;
    font-size: 18px;
    font-weight: 600;
    border-radius: 10px;
    border: 1px solid rgb(37, 99, 235);
    margin: auto;
    display: flex;
    color: white;
    cursor: pointer;
    background-color: rgb(37, 99, 235);
  }
  .welcomepageBlock{
    font-size: 18px;
    font-weight: 600;
    margin: auto;
    width: fit-content;
    /* width: 100%; */
    padding: 2rem 3rem;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0px 0px 5px rgba(80, 80, 80, 0.83);
  }
  .center{
    text-align: center;
    padding: 0.5rem 0px;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  } 
  
  /* Hiding the error boundary */
#webpack-dev-server-client-overlay{
  display: none !important;
  position: relative !important; 
  width: 95% !important;
  height: 1px !important;
  border: none !important;
  z-index: 1 !important;
}

.divScroll {
  /* overflow: scroll; */
}
.divScroll::-webkit-scrollbar {
   width: 7px;
}

.divScroll::-webkit-scrollbar-track {
   border-radius: 10px;
   background-color: #ededed;
}

.divScroll::-webkit-scrollbar-thumb {
   border-radius: 10px;
   background: #bbbbbb;
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


.engage-broken-text{
  color: #06152D;
  font-weight: 700;
  font-size: 21px;
  margin-top: 10px;
}


@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px); 
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeInUp {
  animation: fadeInUp 500ms ease-out; 
}


